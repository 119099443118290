.tariff {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 2px solid #b9b9b9;
  cursor: pointer;

  &:hover {
    border: 2px solid #7dc5ff;
  }

  &.active {
    background-color: #d6ffd1;
    border: 2px solid #239eff;
  }
}

.tariffNoAction {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 2px solid #b9b9b9;
}