.wrapper {
    position: relative;
    margin-bottom: 15px;
}

.container {
    position: relative;
    min-height: 53px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #ced4da;
    border-radius: 3px;

    &.open {
        border-color: #86b7fe;
        box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
    }
}

.iconsView {
    flex-shrink: 0;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.input {
    outline: none;
    border: none;
    width: 100%;
}

.label {
    position: absolute;
    top: 16px;
    left: 12px;
    height: 100%;
    pointer-events: none;
    max-width: calc(100% - 24px);
    max-height: 20px;
    text-align: left;
    overflow: hidden;
    padding-right: 24px;

    &.shift {
        opacity: .65;
        top: 5px;
        font-size: 12px;
    }
}

.icon {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        color: #838383;
    }

    &:hover {
        transform: scale(1.05);

        & svg {
            color: #000000;
        }
    }
}

.errorText {
    color: #ff1b1b;
    margin-top: 8px;
    text-align: center;
}

.options {
    position: absolute;
    outline: none;
    z-index: 100;
    background-color: #fff;
    left: 0;
    border: 1px solid #86b7fe;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
    width: 100%;
    min-height: 93px;
    overflow-y: auto;
}

.option {
    padding: 5px;
    word-break: break-word;

    &:hover {
        background-color: #cae0ff;
    }

    &.select {
        background-color: #67a8ff;
    }
}

.value {
    padding: 25px 11px 5px 11px;
    word-break: break-word;
    text-align: left;
}

.unselectedText {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
