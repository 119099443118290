.burger {
    display: none;
    font-size: 24px;
    align-self: center;
}

.showNavMenuAbsolute.navMenu {
    display: flex;
}

.navMenu:focus-visible {
    outline: none;
}

@media screen and (max-width: 991px) {
    .navMenu {
        z-index: 999;
        position: absolute;
        display: none;
        width: 180px;
        top: 60px;
        right: 0;
        flex-direction: column;
        background: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        padding: 10px;
    }

    .burger {
        display: block;
    }
}

.customDropdown {
    & button {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #0d6efd;
        padding: 0.5rem 1rem;
        border-right: none !important;
    }
    &.active {
        border-radius: 0.25rem;
        background-color: #0d6efd;
        & button {
            color: #fff;
        }
    }
}

.itemWrapper {
    display: flex;
    align-items: center;

    div {
        margin-left: 10px;
    }
}

.profileBlock {
    display: flex;
    flex-direction: column;
    line-height: 18px;
}
