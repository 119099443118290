.container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #939ba2;

    & .text {
        margin-left: 4px;
        font-weight: 600;
    }

    &:hover {
        color: #5a5d5e;
    }
}
