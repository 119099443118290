.wrapper {
    margin-bottom: 15px;
}

.container {
    position: relative;
    min-height: 53px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.container > .input:focus ~ label, .container > .input:not(:placeholder-shown) ~ label {
    opacity: .65;
    top: 7px;
    font-size: 12px;
}

.container:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
}

.container > .input:focus, .container > .input:not(:placeholder-shown) {
    padding-top: 25px;
    padding-bottom: 5px;
}

.iconsView {
    flex-shrink: 0;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.disableContainer {
    background-color: #eaeaea;
}

.input {
    outline: none;
    border: none;
    padding: 15px 11px;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 14px;

    &::placeholder {
        opacity: 0;
    }
}

.label {
    position: absolute;
    top: 16px;
    left: 12px;
    height: 100%;
    pointer-events: none;
}

.icon {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        color: #838383;
    }

    &:hover {
        transform: scale(1.05);

        & svg {
            color: #000000;
        }
    }
}

.errorText {
    color: #ff1b1b;
    margin-top: 8px;
    text-align: center;
}

.inputTextDisable {
    color: black;
}

.inputError {
    border-color: #ff1b1b;
}

.optionsContainer {
    border: 1px solid #86b7fe;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(13, 110, 253, 0.25);
}

.option {
    padding: 2px 5px;

    &:hover {
        cursor: pointer;
        background-color: #e5efff;
    }
}
